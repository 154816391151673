'use strict';
app.factory("errorHandlerService", ['$rootScope', 'broadcastService', '$ocLazyLoad', function ($rootScope, broadcastService, $ocLazyLoad) {
    var errorHandlerServiceFactory = {};

    const httpStatusCode_CustomException = 419;
    function IsJsonString(str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    }

    const _getStatus = (e) => {
        if (_.isNil(e) || _.isNil(e.xhr)) {
            return {
                code: "Unknown error",
                desc: "Please contact our support."
            };
        }

        const { status, errorThrown, responseText } = e.xhr;

        if (status === 0) {
            return {
                code: "Fail to connect to server, please close the form and try again.",
                desc: "Fail to connect to server, please close the form and try again.",
            };
        }

        if ([400, httpStatusCode_CustomException].includes(status)) {
            if (responseText) {
                const { message } = e.xhr.responseJSON ?.["odata.error"] ?? {};

                if (message) {
                    try {
                        const { MsgKey, MsgItem } = JSON.parse(message.value);

                        //214: Duplicate Code.
                        //228: The code is tied to some other table so deletion not allowed.    
                        if (MsgKey === 214 || MsgKey === 228) {
                            return { code: MsgKey, desc: MsgItem };
                        }
                    }
                    catch (_e) {
                        //most likely invalid json string
                        console.error(_e);
                    }
                }
            }

            return {
                code: "You have encountered a bad request.",
                desc: "You have encountered a bad request.",
            };
        }

        if (status === 401) {
            return {
                code: "You have to login/relogin to proceed with this action.",
                desc: "You have to login/relogin to proceed with this action.",
            };
        }

        if (status === 403) {
            return {
                code: "You don't have permission to perform this action.",
                desc: "You don't have permission to perform this action.",
            };
        }

        if (status === 404) {
            return {
                code: "This record is not found.",
                desc: "This record is not found."
            };
        }

        if (status === 414)
        {
            return {
                code: "The request URI is too long. Please retry with fewer/shorter filter/parameter.",
                desc: "The request URI is too long. Please retry with fewer/shorter filter/parameter."
            };
        }

        if (status === 500) {
            if (errorThrown === "This record is currently in used." || errorThrown === "This record is currently in use.") {
                return {
                    code: "This record is currently in used.",
                    desc: "This record is currently in used.",
                };
            }

            if (responseText) {
                const errCode = responseText
                    .substring(
                        responseText.indexOf("innererror") + 32,
                        responseText.indexOf("type") - 3
                    )
                    .replace(/(\\r|\\n|\\)/g, "");

                const errDesc = responseText
                    .substring(
                        responseText.lastIndexOf("message") + 10,
                        responseText.lastIndexOf("type") - 3
                    )
                    .replace(/(\\r|\\n|\\)/g, "");

                if (
                    errorThrown === "This record is currently in use." ||
                    errorThrown === "This record is currently in used." ||
                    errDesc
                        .toLowerCase()
                        .includes("conflicted with the reference constraint") ||
                    errDesc
                        .toLowerCase()
                        .includes("conflicted with the check constraint")
                ) {
                    return {
                        code: "This record is currently in used.",
                        desc: "This record is currently in used.",
                    };
                }

                const msgHeader = errDesc.replace(/\"|\'/g, "&quot;");

                return {
                    code: `${errCode}<a ng-click="$parent.msgHeader='${msgHeader}'; $event.stopPropagation();"></a>`,
                };
            }
        }

        return { code: "Unknown error", desc: "Please contact our support." };
    };


    var _getStatusAPI = function (status, data, e) {
        if (status == 0)
            return "Fail to connect to server, please close the form and try again.";
        if (status == 400)
            return data.Message;
        if (status == 401)
            return "You have to login/relogin to proceed with this action.";
        if (status == 403)
            return "You don't have permission to perform this action.";
        if (status == httpStatusCode_CustomException)
            return data.Message;
        if (status == 414)
            return "The request URI is too long. Please retry with fewer/shorter filter/parameter.";
        if (status == 500) {
            if (data.ExceptionMessage) {
                //We need to compile a message for user in this case
                if (data.ExceptionMessage == "Document has been applied/voided.")
                    return "Document has been applied/voided.";
                if (data.ExceptionMessage == "Document has been overpaid.")
                    return "Document has been overpaid.";
                if (data.ExceptionMessage == "Document quantity has been over applied.")
                    return "Document quantity has been over applied.";
                if (data.ExceptionMessage == "Document has been over returned.")
                    return "Document has been over returned.";
                if (data.ExceptionMessage == "Duplicate From Date/To Date is not allowed.")
                    return "Duplicate From Date/To Date is not allowed.";
                if (data.ExceptionMessage.contains("ApprovalSubmission|IApprovalService"))
                    return "Fail to submit! Please contact LintraMax Support, instance id = '" + data.ExceptionMessage.substring(data.ExceptionMessage.lastIndexOf("'"), 85) + "'"
                if (data.ExceptionMessage.contains("MthEmyp over MD"))
                    return (data.ExceptionMessage).substr(((data.ExceptionMessage).indexOf('-') + 1), (data.ExceptionMessage).length);
                if (data.ExceptionMessage.contains("has been reconciled"))
                    return data.ExceptionMessage;
                if (data.ExceptionMessage.contains("Annual Tax Error"))
                    return data.ExceptionMessage;
                if (data.InnerException != undefined) {
                    const exceptionMessage = data?.InnerException?.InnerException?.ExceptionMessage?.toLowerCase() || '';
                    if (exceptionMessage.includes("conflicted with the reference constraint") ||
                        exceptionMessage.includes("conflicted with the check constraint")) {
                        return "This record is currently in use.";
                    }
                } 

                let errCode = data.ExceptionMessage;
                let errDesc = (data.InnerException != undefined) ? data.InnerException.ExceptionMessage : "";
                errCode += "<a ng-click=\"$parent.msgHeader = '" + errDesc.replace(/\"|\'/g, "&quot;") + "'; $event.stopPropagation();\"></a>";
                return errCode;
            }
            else {
                const innerErrorMsg = (data ?.["odata.error"] ?.innererror ?.internalexception ?.internalexception ?.message);
                if (!_.isNil(innerErrorMsg) && (innerErrorMsg.toLowerCase().includes(("conflicted with the REFERENCE constraint").toLowerCase()) || innerErrorMsg.toLowerCase().includes(("conflicted with the CHECK constraint").toLowerCase())))
                    return "This record is currently in used."
                //As observed, this is thrown by odata controller with HTTP request
                return data["odata.error"].innererror.message;
            }
        }

        return "Unknown Error. Please contact our support.";
    }

    /* Specifically Handle Grid Exception */
    var _gridException = function (e) {
        if (e.status == "error") {
            $rootScope.unauthorized = true;//Check whether its connection or session expired
            $rootScope.$emit("CallReloginMethod", {});
            return _getStatus(e);
        }
        else if (typeof e.status == "object"
            && typeof e.errorThrown != "undefined"
            && (e.errorThrown.statusText === "error" || e.errorThrown.statusText == "Internal Server Error")) {
            $rootScope.unauthorized = true;//Check whether its connection or session expired
            $rootScope.$emit("CallReloginMethod", {});

            let _e = _.cloneDeep(e)

            _e.xhr.status = _e.errorThrown.status;
            _e.xhr.responseText = _e.errorThrown.responseText;
            _e.xhr.responseJSON = _e.errorThrown.responseJSON;

            return _getStatus(_e);
        }
    }

    /*We handle the error status and msg returned,
    Refers:- http://msdn.microsoft.com/en-us/library/system.net.httpstatuscode(v=vs.110).aspx
           ConnectionFailed = 0,
           BadRequest = 400,
           Unauthorize = 401,
           Forbidden = 403,
           MethodNotAllowed = 405,
           InternalServerError = 500

           ** We will add in additional error from time to time
       */
    var _apiException = function (data, status, headers, config) {
        $rootScope.unauthorized = true;//Check whether its connection or session expired
        $rootScope.$emit("CallReloginMethod", {});

        if (typeof status == "undefined" && typeof data.status != "undefined") {
            return _getStatusAPI(data.status, data.data);
        }
        else {
            return _getStatusAPI(status, data);
        }
    }

    errorHandlerServiceFactory.gridException = _gridException;
    errorHandlerServiceFactory.apiException = _apiException;

    return errorHandlerServiceFactory;
}]);
